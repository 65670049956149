import { IUser } from 'interfaces'
import axios from 'axios'
import { AUTH_KEY } from 'values'

export const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(
  (request) => {
    const auth = localStorage.getItem(AUTH_KEY)
    if (auth) {
      const user = JSON.parse(auth)

      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${ user.token }`
      } else {
        request.headers = { Authorization: `Bearer ${ user.token }` }
      }
    }

    return request
  }
)

export const API = {
  authorize: (code: string, service: string) =>
    axiosInstance.post<IUser>('/api/authorize', { code, service })
}