import React from 'react'
import { TitleProps, useRouterContext } from '@pankod/refine-core'
import { Logo } from 'images'

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { Link } = useRouterContext()

  return (
    <Link to="/" href="/">
      { collapsed ? (
        <img
          src={ Logo }
          alt="DrunkenDoll"
          style={ {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px 24px",
          } }
        />
      ) : (
        <img
          src={ Logo }
          alt="DrunkenDoll"
          style={ {
            width: "200px",
            padding: "12px 24px",
          } }
        />
      ) }
    </Link>
  )
}
