import { AuthProvider, HttpError } from '@pankod/refine-core'
import { AUTH_KEY } from 'values'
import { IUser } from 'interfaces'

export const authProvider = (): AuthProvider => {
  return {
    login: ({ user }: { user?: IUser }) => {
      console.log('login')
      if (!user)
        return Promise.reject()

      localStorage.setItem(AUTH_KEY, JSON.stringify(user))
      return Promise.resolve()
    },
    logout: (props: { redirectPath: string }) => {
      console.log('logout')
      localStorage.removeItem(AUTH_KEY)
      return Promise.resolve(props?.redirectPath)
    },
    checkError: (error: HttpError) => {
      console.log('checkError')
      if (error?.response?.status === 401) {
        return Promise.reject({ redirectPath: "/" })
      }
      return Promise.resolve()
    },
    checkAuth: () => {
      console.log('checkAuth')
      if (localStorage.getItem(AUTH_KEY))
        return Promise.resolve()
      else {
        return Promise.reject({ redirectPath: "/login" })
      }
    },
    getPermissions: () => {
      console.log('getPermissions')
      const auth = localStorage.getItem(AUTH_KEY)
      if (auth) {
        const parsedUser = JSON.parse(auth)
        return Promise.resolve(parsedUser.roles)
      }
      return Promise.reject()
    },
    getUserIdentity: () => {
      console.log('getUserIdentity')
      const auth = localStorage.getItem(AUTH_KEY)
      if (auth) {
        const parsedUser = JSON.parse(auth)
        return Promise.resolve(parsedUser)
      }
      return Promise.reject()
    }
  }
}