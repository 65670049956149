import { List, NumberField, Table, TextField, useTable, } from "@pankod/refine-antd"

import { IHolder } from "interfaces"

export const HolderList = () => {
  const { tableProps } = useTable<IHolder>({
    dataProviderName: 'holder',
  })

  return (
    <List pageHeaderProps={ { subTitle: 'preview' } }>
      <Table { ...tableProps } rowKey="id">
        <Table.Column dataIndex="fullname" title="Discord user"/>
        <Table.Column
          dataIndex="holding"
          title="Holding"
          render={ (value) => <NumberField value={ value }/> }
        />
        <Table.Column
          dataIndex="address"
          title="Wallet"
          render={ (value) => <TextField value={ value }/> }
        />

      </Table>
    </List>
  )
}