const theWords = [
  '🍺주(酒)께서 가라사대🍺\n첫 잔은 무조건 원샷일 지어다.',
  '🍺주(酒)께서 가라사대🍺\n공복에 마시지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n주량을 알아둘 지어다.',
  '🍺주(酒)께서 가라사대🍺\n음주운전을 절대 금할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n몸이 아플 땐 무리해서 마시지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n타인에게 강요는 절대 금할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n여행을 갔다면 그 지역의 술을 먹어볼 지어다.',
  '🍺주(酒)께서 가라사대🍺\n속도를 지키며 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n오늘 마실 술을 내일로 미루지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n첫 잔은 무조건 소맥일 지어다.',
  '🍺주(酒)께서 가라사대🍺\n소주와 맥주의 황금비율은 3:7인 것을 의심치 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n취중에 SNS를 뒤지지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n좋은 술은 네 이웃과 나눌 때 더욱 달콤할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n숙취에 시달리며 "다시는 술 안먹어"라는 거짓을 일삼지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n슬픔은 주님과 함께할 때 절반이 됨을 믿을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n과한 술은 네 친구임을 의심치 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n술기운을 빌려 사랑고백을 하지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n힘들 땐 힘내자고 한잔할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n쉬는 날엔 낮술을 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n기쁠 땐 만끽하며 한잔할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n조기은퇴를 기원하며 한잔할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n퇴근 후 피로를 씻어내기 위해 한잔할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n오늘 먹은 안주는 살이 안 찔 지어다.',
  '🍺주(酒)께서 가라사대🍺\n뭔가 안풀릴 땐 잠깐 쉬고 한잔할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n좋은 음식엔 좋은 술을 곁들일 지어다.',
  '🍺주(酒)께서 가라사대🍺\n취중에도 평소와 같이 처신할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n제철에 맞는 안주를 주기적으로 섭취할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n주량을 뽐내지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n건배사를 하나쯤은 숙지하고 있을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n취중에도 네 집에 돌아가는 길을 잊지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n죄는 음주가 아니며, 과음이 죄일지어다',
  '🍺주(酒)께서 가라사대🍺\n주종을 가릴 시간이 있다면, 한 잔이라도 더 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n소: 소한 행복은 / 주:류와 적절한 안주일지어다.',
  '내가 가로되, 주(酒)여 어찌 잔을 쉬어주지 않나이까\n🍺주(酒)께서 가라사대🍺\n쫄?ㅋ',
  '🍺주(酒)께서 가라사대🍺\n피와 맥에는 진리가 있을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n음주는 만병통치약이니, 마신자 벌떡 일어서고 안마신자 속죄 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n숙취를 얻지 않는 최고의 방법은 술에서 깨지 않는 것일지어다.',
  '🍺주(酒)께서 가라사대🍺\n아무리 추워봐라 옷 사입나 술 사먹지.',
  '🍺주(酒)께서 가라사대🍺\n왼손이 마시는 술잔을 오른손도 거들게 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n비오는 날엔 막걸리를 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n들면 술 잔이니, 내리면 빈 잔일지어다.',
  '🍺주(酒)께서 가라사대🍺\n술 마시고 똑같은 말만 내뱉지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n오늘도 너는 술로 날을 새우니, 밤은 깊어 가느니라.',
  '🍺주(酒)께서 가라사대🍺\n해장에는 꼭 해장 술이 함께 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n취한 채로 디스코드에서 길을 잃은 어린 양이 되지 말지어다.',
  '🍺주(酒)께서 가라사대🍺\n술을 남기는 자는 지옥에서 그 술을 모두 마시게 될 지어니... 호우 땡쓰 맨~!',
  '🍺주(酒)께서 가라사대🍺\n나는 늘 너와 함께 하나니, 주종과 시간을 가리지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n국밥과 소주만 있었다면 악인은 태어나지 않았을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n때론 백 마디의 말보다 술 한잔이 더 큰 위로가 될 지어다.',
  '🍺주(酒)께서 가라사대🍺\n취기를 느끼기 시작했을 때가 진정한 시작일지어다.',
  '🍺주(酒)께서 가라사대🍺\n취했다는 것은 살아있다는 반증일지어다.',
  '🍺주(酒)께서 가라사대🍺\n술이란 마시고 싶을 때 찾는 것이 아니니, 시간을 내어 마시는 것일지어다.',
  '🍺주(酒)께서 가라사대🍺\n안주는 거들 뿐일지어다.',
  '🍺주(酒)께서 가라사대🍺\n남보다 한 잔 더 마신걸 다른 사람이 모르게 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n내 술은 내 술이요, 니 술도 내 술일지어다.',
  '🍺주(酒)께서 가라사대🍺\n빠르게 취하지 말 지어다. 술 마실 시간은 아직 여유로울 터이니.',
  '🍺주(酒)께서 가라사대🍺\n술 앞에서는 모두 공평 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술은 즐기는 것이니, 이기는 것이 아닐 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술 한 잔 이면 걱정을 털고, 두 잔이면 아픔을 털 지어다.',
  '🍺주(酒)께서 가라사대🍺\n네 취함을 벗에게 알리지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n가는 잔이 가득해야 오는 잔도 가득할 지어다. ',
  '🍺주(酒)께서 가라사대🍺\n말이 많으면 마실 술이 적을 지어다. ',
  '🍺주(酒)께서 가라사대🍺\n하룻강아지 주당 무서운줄 모를 지어다.',
  '🍺주(酒)께서 가라사대🍺\n이 또한 마셔 질 지어다.',
  '🍺주(酒)께서 가라사대🍺\n입이 삐뚤어졌어도 술은 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n음식은 편식 할 지어도, 술은 편주하지 않으리.\n주종을 가리지 않는 자에겐 축복을 내릴 지어다.',
  '🍺주(酒)께서 가라사대🍺\n 한마디 더 할 시간에 한 잔이라도 더 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술로 너희들을 어지럽히지 아니하고\n용기있는 사람으로 복돋아 줄 지어다.',
  '🍺주(酒)께서 가라사대🍺\n내 너희들에게 힘듦을 술로 이겨낼 수 있도록 주를 창조 하였느니.',
  '🍺주(酒)께서 가라사대🍺\n술은 합법적이니 언제든지 즐길 지어다.',
  '🍺주(酒)께서 가라사대🍺\n사람이 살아가는 필요한 세가지는 의, 식, 주(酒) 가 있나니,\n이는 인간을 널리 이롭게 하는데 반드시 필요한 것들이니라.',
  '🍺주(酒)께서 가라사대🍺\n출근을 두려워 하지 않는다면, 퇴근 후 한잔이 짜릿할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n가주(酒)아!',
  '🍺주(酒)께서 가라사대🍺\n입으로 들어가는 술과 음식은 죄가 아니니,\n입에서 나오는 것이 비로소 죄가 될 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술 마시는 시간이 낭비라 생각하지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술이란 진귀한 것 일지어니.\n술 게임은 진 사람이 아닌,\n이기는 사람이 진정으로 취해야 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\nNO BEER, NO LIFE 라 하였다.',
  '🍺주(酒)께서 가라사대🍺\n나는 회요, 곱창전골이요, 매운 새우깡이다.\n나를 통하지 않고서는 아무도 참이슬께 갈 수 없다.\n주멘.',
  '🍺주(酒)께서 가라사대🍺\n바텐더가 너의 주문을 알아듣는다면 아직 취한 게 아닐 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술이란 마지막 한방울 까지 귀한 것이니, 밑잔은 깔지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n너의 안주도 사랑 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n첫 잔은 음주의 기쁨을 축하하기 위해 원샷일지어다.',
  '🍺주(酒)께서 가라사대🍺\n낮술이란 취기를 더 오래 즐길 수 있는 방법일지어다.',
  '🍺주(酒)께서 가라사대🍺\n하루의 시작과 끝은 술일지어다.\n그리고 그것이 드돌일지어다.',
  '🍺주(酒)께서 가라사대🍺\n언제까지 어깨춤을 추게 할 것인가?',
  '🍺주(酒)께서 가라사대🍺\n술이 있는 한 희망은 있을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n사람의 불행과 행복을 좌우하는 것은 술일지어다.',
  '🍺주(酒)께서 가라사대🍺\n주사는 미워하되 술은 미워하지 말 지어다.',
  '🍺주(酒)께서 가라사대🍺\n맛있게 취하면 0칼로리일지어다.',
  '🍺주(酒)께서 가라사대🍺\n집은 두 발로 걸어서 가는 곳이 아닐 지어다.',
  '🍺주(酒)께서 가라사대🍺\n치킨에 콜라는 유죄일지어다.',
  '🍺주(酒)께서 가라사대🍺\n그때에 주께서 말씀하실지어니, 과음은 죽음에 이르게 하는 사탄과 같을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n취하면 보이지 않던 많은 것을 볼 수 있으나,\n보이던 것들도 보이지 않게 될 지어다.',
  '🍺주(酒)께서 가라사대🍺\n안주는 남겨도 술은 남기지 말지어다.\n술은 우리의 생명수니라.',
  '🍺주(酒)께서 가라사대🍺\n술이 없는 곳으로 향하지 않을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n잔치집에서 마시는 술은 축하에서 비롯됨이지,\n알콜을 탐해서가 아님이라.',
  '🍺주(酒)께서 가라사대🍺\n하루에 여덟 잔의 물은 불가능 하게 느껴질 지어니,\n그러나 여덟 잔의 맥주는 쉽게 취할 수 있을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술을 마시면 계속해서 돈이 사라지나,\n술을 취하지 않아도 돈은 없어지느니라.',
  '🍺주(酒)께서 가라사대🍺\n살 찌는 소리보다 짠 하는 소리에 더 집중 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n숙취가 없는 자, 비로소 천운을 가진자라 일컫느니라.',
  '🍺주(酒)께서 가라사대🍺\n술은 실로 우리의 적이니, 마셔서 없애버릴 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술과 고기는 적당히 익힐수록 제 맛일지어다.',
  '🍺주(酒)께서 가라사대🍺\n벗과 술은 시간이 지날수록 제 맛일지어다.',
  '🍺주(酒)께서 가라사대🍺\n이상은 높고, 사랑은 깊고, 잔은 평등 할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n밥 숟가락 들 힘이 있다면, 술잔을 들 지어다.',
  '🍺주(酒)께서 가라사대🍺\n마시고 있어도 마시고 싶은게 술일지어다.',
  '🍺주(酒)께서 가라사대🍺\n다 마신 술병도 다시 볼 지어다.',
  '🍺주(酒)께서 가라사대🍺\n오늘 밤 주(酒)인공은 너일지어다.',
  '🍺주(酒)께서 가라사대🍺\nNFT 살 돈이 있다면, 그것으로 한 잔 더 마실 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술 없는 모임은 의미가 없을 지어다.',
  '🍺주(酒)께서 가라사대🍺\n술은 헤어진 연인을 잊기 위해 필요한 것일지어다. (주酒세기 1:1)',
  '🍺주(酒)께서 가라사대🍺\n술은 새로운 인연을 맺기 위해 필요한 것일지어다. (주酒세기 1:2)',
  '🍺주(酒)께서 가라사대🍺\n술은 더 나은 인생을 살기 위해 필요한 것일지어다. (주酒세기 1:3)',
  '🍺주(酒)께서 가라사대🍺\n먼저 뻗으면 네 지갑이 열릴지어니,\n필히 끝까지 버틸 지어다.',
  '🍺주(酒)께서 가라사대🍺\n어제 먹은 술은 아무 소용이 없느니,\n오늘의 술을 다시 취할 지어다.',
  '🍺주(酒)께서 가라사대🍺\n원수를 외나무다리에서 만나도 술 한잔에 친구가 될 지어다.'
]

export const RandomWords = () => {
  const wordId = Math.floor(Math.random() * theWords.length)
  return theWords[wordId]
}
