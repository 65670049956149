import { List, Typography } from "@pankod/refine-antd"

import { RandomWords } from 'api/words'

const { Title, Text } = Typography

export const Dashboard = () => {

  return (
    <List>
      <Title level={ 5 }>/주멘</Title>
      <Text style={ { whiteSpace: 'pre' } }>{ RandomWords() }</Text>
    </List>
  )
}