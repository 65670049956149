import { AntdLayout, Button } from "@pankod/refine-antd"
import { DiscordAuthURI } from 'values'
import { useLogin } from '@pankod/refine-core'
import { useEffect, useState } from 'react'
import { API } from 'api/endpoints'
import { Logo } from 'images'
import { IUser } from 'interfaces'

export const DiscordLogin: React.FC = () => {
  const { mutate: login } = useLogin<{ user?: IUser }>()
  const [ isLoading, setLoading ] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    const service = queryParams.get('service')

    if (code && service) {
      setLoading(true)
      API.authorize(code, service)
      .then(({ data }) => {
        login({ user: data })
      })
      .catch(() => {
        login({})
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }, [ login ])

  const loginWithRedirect = () => {
    window.location.href = DiscordAuthURI
  }

  return (
    <AntdLayout
      style={ {
        background: '#03060a',
        backgroundSize: "cover",
      } }
    >
      <div style={ { height: "100vh", display: "flex" } }>
        <div style={ { maxWidth: "200px", margin: "auto" } }>
          <div style={ { marginBottom: "28px" } }>
            <img src={ Logo } alt="Drunken Doll"/>
          </div>
          <Button
            type="primary"
            size="large"
            style={ { backgroundColor: '#2d252a' } }
            block
            loading={ isLoading }
            onClick={ () => loginWithRedirect() }
          >
            SIGN IN
          </Button>
        </div>
      </div>
    </AntdLayout>
  )
}