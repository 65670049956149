import {
  DateField,
  EditButton,
  FilterDropdown,
  List,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useSelect,
  useTable,
} from "@pankod/refine-antd"

import { ICategory, IPost } from "interfaces"
import { useMany } from '@pankod/refine-core'

export const PostList = () => {
  const { tableProps } = useTable<IPost>()

  const categoryIds =
    tableProps?.dataSource?.map((item) => item.category.id) ?? []
  const { data: categoriesData, isLoading } = useMany<ICategory>({
    resource: "categories",
    ids: categoryIds,
    queryOptions: {
      enabled: categoryIds.length > 0,
    },
  })
  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
  })

  return (
    <List pageHeaderProps={ { subTitle: 'sample' } }>
      <Table { ...tableProps } rowKey="id">
        <Table.Column dataIndex="title" title="title"/>
        <Table.Column
          dataIndex="status"
          title="status"
          render={ (value) => <TagField value={ value }/> }
        />
        <Table.Column
          dataIndex="createdAt"
          title="createdAt"
          render={ (value) => <DateField format="LLL" value={ value }/> }
        />
        <Table.Column
          dataIndex={ [ "category", "id" ] }
          title="category"
          render={ (value) => {
            if (isLoading) {
              return <TextField value="Loading..."/>
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find(
                    (item) => item.id === value,
                  )?.title
                }
              />
            )
          } }
          filterDropdown={ (props) => (
            <FilterDropdown { ...props }>
              <Select
                style={ { minWidth: 200 } }
                mode="multiple"
                placeholder="Select Category"
                { ...categorySelectProps }
              />
            </FilterDropdown>
          ) }
        />
        <Table.Column<IPost>
          title="Actions"
          dataIndex="actions"
          render={ (_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton
                  size="small"
                  recordItemId={ record.id }
                  hideText
                />
                <EditButton
                  size="small"
                  recordItemId={ record.id }
                  hideText
                />
              </Space>
            )
          } }
        />
      </Table>
    </List>
  )
}