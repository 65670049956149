import { Refine } from "@pankod/refine-core"
import 'styles/antd.less'
import '@pankod/refine-antd/dist/styles.min.css'
import 'styles/style.css'

import routerProvider from "@pankod/refine-react-router-v6"
import { authProvider } from 'authProvider'
import { PostList } from 'pages/posts/list'
import { PostShow } from 'pages/posts/show'
import { PostEdit } from 'pages/posts/edit'
import { ErrorComponent, Layout, notificationProvider } from '@pankod/refine-antd'
import { Title } from 'components/title'
import { DiscordLogin } from 'pages/login'
import { HolderList } from 'pages/holders/list'
import { DefaultDataProvider, HolderDataProvider } from 'api/dataProvider'
import { Dashboard } from 'pages/dashboard'
import { axiosInstance } from 'api/endpoints'
import { FiLayers } from 'react-icons/fi'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { MdDashboard } from 'react-icons/md'

const App = () => {
  return (
    <Refine
      LoginPage={ DiscordLogin }
      authProvider={ authProvider() }
      routerProvider={ {
        ...routerProvider,
        routes: []
      } }
      dataProvider={ {
        holder: HolderDataProvider("/api/v1", axiosInstance),
        default: DefaultDataProvider("https://api.fake-rest.refine.dev", axiosInstance),
      } }
      Title={ Title }
      Layout={ (({ children }) => Layout({ children })) }

      notificationProvider={ notificationProvider }
      catchAll={ <ErrorComponent/> }

      resources={ [
        {
          name: 'dashboard',
          icon: (<MdDashboard/>),
          list: Dashboard
        },
        {
          name: 'holders',
          icon: (<HiOutlineUserGroup/>),
          list: HolderList,
        },
        {
          name: 'posts',
          icon: (<FiLayers/>),
          list: PostList,
          show: PostShow,
          edit: PostEdit,
        },
      ] }
    />
  )
}

export default App